import { FC } from "react";
import roto from '../../../../../local/png/partners/Roto.webp';
import brusbox from '../../../../../local/png/partners/Brusbox.webp';
import rehau from '../../../../../local/png/partners/Rehau.webp';
import { isMobile } from "react-device-detect";
import './AnimatedSection.scss';

//Partners logos and info
export const AnimatedSection: FC = () => {
    return(
        <div className={`animation animation__is-mobile-${isMobile}`}>
            <div className="upper">
                <div className="animation__partners-line-container first">
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element roto" src={roto}/>
                    </div>
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element brusbox" src={brusbox}/>
                    </div>
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element rehau" src={rehau}/>
                    </div>
                </div>
                <div className="animation__partners-line-container second">
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element roto" src={roto}/>
                    </div>
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element brusbox" src={brusbox}/>
                    </div>
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element rehau" src={rehau}/>
                    </div>
                </div>
            </div>
            <div className="lower">
                <div className="animation__partners-line-container first">
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element roto" src={roto}/>
                    </div>
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element brusbox" src={brusbox}/>
                    </div>
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element rehau" src={rehau}/>
                    </div>
                </div>
                <div className="animation__partners-line-container second">
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element roto" src={roto}/>
                    </div>
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element brusbox" src={brusbox}/>
                    </div>
                    <div className="animation__partners-img-container">
                        <img className="animation__partners-img-element rehau" src={rehau}/>
                    </div>
                </div>
            </div>
        </div>
    );
}