import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const pathState = (state:RootState) => state.path;

const getCurrentProductAnkor = createSelector(pathState, state => state.ankor);

//const getMessageSendedStatus = createSelector(pathState, state => state.msgSendStatus);

export const pathSelectors = {
    //getMessageSendedStatus,
    getCurrentProductAnkor,

}