import { FC } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../../local/svg/products/arrow.svg";
import aero from '../../../../local/png/products/aero.webp';
import aeroBkg from '../../../../local/png/products/aero-bkg.webp';
import superAero from '../../../../local/png/products/super-aero.webp';
import superAeroBkg from '../../../../local/png/products/super-aero-bkg.webp';
import rehau from '../../../../local/png/products/Rehau.webp';
import rehauBkg from '../../../../local/png/products/Rehau-bkg.webp';
import kale from '../../../../local/png/products/kale.webp';
import kaleBkg from '../../../../local/png/products/kale-bkg.webp';
import { isMobile } from "react-device-detect";
import './Products.scss';
import { useAppDispatch } from "../../../../store/store.hooks";
import { pathActions } from "../../../../store/path/path.slice";

export const Products: FC = () => {
    const dispatch = useAppDispatch();

    
    return(
        <section className={`products products__is-mobile-${isMobile}`}>
            <div className="products__products-subcontainer">
                <div className="products__product-container">
                    <h3 className="products__product-heading">
                        ПРОФИЛЬ BRUSBOX AERO (60-3)
                    </h3>
                    <img src={aero} className="products__product-img-front aero-front" />
                    <div className="products__product-link-container" >
                        <p className="products__product-link-title" >
                            Подробнее
                        </p>
                        <Link to='/details/#aero' className="products__product-svg-container" onClick={() => dispatch(pathActions.setAnkor('aero'))}>
                            <Arrow className="products__product-svg-element" />
                        </Link>
                    </div>
                    <img src={aeroBkg} className="products__product-img-back aero-bkg" />
                </div>
                <div className="products__product-container">
                    <h3 className="products__product-heading">
                        BRUSBOX Профиль X SUPER AERO (70-5)
                    </h3>
                    <img src={superAero} className="products__product-img-front super-aero-front" />
                    <div className="products__product-link-container" >
                        <p className="products__product-link-title" >
                            Подробнее
                        </p>
                        <Link to='/details/#super-aero' className="products__product-svg-container" onClick={() => dispatch(pathActions.setAnkor('super-aero'))}>
                            <Arrow className="products__product-svg-element" />
                        </Link>
                    </div>
                    <img src={superAeroBkg} className="products__product-img-back super-aero-bkg" />
                </div>
            </div>
            <div className="products__products-subcontainer">
                <div className="products__product-container">
                    <h3 className="products__product-heading">
                        Профиль Rehau Grazio (70-5)
                    </h3>
                    <img src={rehau} className="products__product-img-front rehau-front" />
                    <div className="products__product-link-container" >
                        <p className="products__product-link-title" >
                            Подробнее
                        </p>
                        <Link to='/details/#rehau' className="products__product-svg-container" onClick={() => dispatch(pathActions.setAnkor('rehau'))}>
                            <Arrow className="products__product-svg-element" />
                        </Link>
                    </div>
                    <img src={rehauBkg} className="products__product-img-back rehau-bkg" />
                </div>
                <div className="products__product-container">
                    <h3 className="products__product-heading">
                        Фурнитура KALE
                    </h3>
                    <img src={kale} className="products__product-img-front kale-front" />
                    <div className="products__product-link-container" >
                        <p className="products__product-link-title" >
                            Подробнее
                        </p>
                        <Link to='/details/#kale' className="products__product-svg-container" onClick={() => dispatch(pathActions.setAnkor('kale'))}>
                            <Arrow className="products__product-svg-element" />
                        </Link>
                    </div>
                    <img src={kaleBkg} className="products__product-img-back kale-bkg" />
                </div>
            </div>
        </section>
    );
}