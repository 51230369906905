import { FC } from "react";
import { Link } from "react-router-dom";
import './Banner.scss';
import bkg from '../../../../local/png/banner/banner.webp';
import { isMobile } from "react-device-detect";
import { bl } from "../../../../BL/BL";

// Intro page. Wide screen landing info
export const Banner: FC = () => {

    return(
        <section id="main-page" className={`banner banner__is-mobile-${isMobile}`}>
            <div className="banner__header-container">
                <h2 className="banner__header-heading">
                    ЛидерПласт — Ваш <br/>надежный партнер <br/>в мире оконных <br/>инноваций
                </h2>
                <Link className="banner__header-link" to='/#contact-us' onClick={() => bl.scrollToSection('contact-us')}>
                    Оставить заявку
                </Link>
            </div>
            <img className="banner__bkg" src={bkg} />
        </section>
    );
}