import { FC, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { MainPage } from "./MainPage/MainPage";
import { ProductsDetails } from "./ProductsDetails/ProductsDetails";
import { isMobile } from "react-device-detect";
import './Body.scss';

export const Body: FC = () => {
    
    return(
        <div className={`body body__is-mobile-${isMobile}`}>
            <Routes>
                <Route path="/" element={<MainPage/>} />
                <Route path="/details" element={<ProductsDetails />} />
            </Routes>
        </div>
    );
}