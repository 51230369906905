import { FC } from "react";
import { ReactComponent as Logo } from '../../../../local/svg/contacts/logo.svg';
import { isMobile } from "react-device-detect";
import './Credits.scss';

export const Credits: FC = () => {
    return(
        <section className={`credits credits__is-mobile-${isMobile}`}>
            <div className="credits__logo-container">
                <Logo className="credits__logo-svg" />
                <h2 className="credits__logo-heading">
                    Окна, которые делают дом уютным!
                </h2>
            </div>
            <div className="credits__description-container">
                <p className="credits__description-copyright">
                    © Лидерпласт 2024
                </p>
                <a href="https://smm-ibis.by/" className="credits__description-developer">
                    Cайт разработан  IBIS marketing
                </a>
            </div>
        </section>
    );
}