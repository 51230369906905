import emailjs from '@emailjs/browser';
import axios from 'axios';

const mailServiceKey = process.env.REACT_APP_MAIL_SERVICE_API_KEY as string;
const mailTemplateKey = process.env.REACT_APP_MAIL_TEMPLATE_KEY as string;
const mailApiKey = process.env.REACT_APP_PUBLIC_API_MAIL_KEY as string;
const tgChatApiToken = process.env.REACT_APP_BOT_TOKEN as string;
const tgChatGroupKey = process.env.REACT_APP_CHAT_ID as string;

const telegramApiUri = `https://api.telegram.org/bot${tgChatApiToken}`;

const sendMailServiceForm = async (formTemplate: string) => {
    emailjs.sendForm(mailServiceKey,mailTemplateKey, formTemplate, mailApiKey);
}

const sendTelegramBotMessage = (message: string) => {
    axios.post(telegramApiUri+"/sendMessage", {
        chat_id: tgChatGroupKey,
        text: message
    })
    /*.then(response => {
        dispatch(pathActions.setMessageSendedStatus(true));
    })*/
    
}

export const dal = {
    sendMailServiceForm,
    sendTelegramBotMessage
}