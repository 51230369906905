import { FC } from "react";
import first from "../../../../local/png/aboutUs/1.webp";
import second from "../../../../local/png/aboutUs/2.webp";
import third from "../../../../local/png/aboutUs/3.webp";
import { isMobile } from "react-device-detect";
import { ReactComponent as Arrow } from '../../../../local/svg/about/arrow.svg';
import './About.scss';

export const About: FC = () => {
    return(
        <section className={`about about__is-mobile-${isMobile}`} id="about">
            <div className="about__heading-container">
                <div className="about__heading-border"> </div>
                <h2 className="about__heading-title">
                    О нас
                </h2>
                <Arrow className="about__heading-svg" />
            </div>
            <div className="about__row-container">
                <div className="about__info-container first-info">
                    <div className="about__info-text">
                        <h3 className="about__info-title">
                            01.
                        </h3>
                        <p className="about__info-description">
                            Наша компания имеет глубокие корни в индустрии оконных конструкций. <br className="about__info-br"/>
                            В данном  направлении мы движемся с 2012 года.<br/>
                        </p>
                    </div>
                    <img className="about__info-img" src={first} />
                </div>
                <div className="about__info-container second-info">
                    <div className="about__info-text">
                        <h3 className="about__info-title">
                            02.
                        </h3>
                        <p className="about__info-description">
                            Наш успех основан на  стремлении к качеству, надежности и высокому <br className="about__info-br"/>
                            уровню обслуживания. Наша цель - создавать комфортные и безопасные <br className="about__info-br"/>
                            дома с помощью  современных оконных решений. В настоящее время мы <br className="about__info-br"/>
                            продолжаем развиваться и совершенствоваться, чтобы оставаться вашим <br className="about__info-br"/>
                            надежным партнером в мире оконных инноваций.<br className="about__info-br"/>
                        </p>
                    </div>
                    <img className="about__info-img" src={second} />
                </div>
                <div className="about__info-container third-info">
                    <div className="about__info-text">
                        <h3 className="about__info-title">
                            03.
                        </h3>
                        <p className="about__info-description">
                            С самого начала нашего пути мы стремились предоставить клиентам самые <br className="about__info-br"/> 
                            качественные и инновационные продукты в области оконных технологий. <br className="about__info-br"/>
                            Мы постепенно расширились и углубили наш опыт, став одним из ведущих <br className="about__info-br"/>
                            дилеров окон ПВХ в Могилеве и Могилевской области. Сегодня в нашем <br className="about__info-br"/>
                            арсенале тысячи кейсов с благодарностями клиентов.
                        </p>
                    </div>
                    <img className="about__info-img" src={third} />
                </div>
            </div>
        </section>
    );
}