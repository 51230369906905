import { FC } from "react";
import { ReactComponent as Deadlines } from '../../../../local/svg/whyUs/deadlines.svg';
import { ReactComponent as Varanity } from "../../../../local/svg/whyUs/varranity.svg";
import { ReactComponent as Individual } from "../../../../local/svg/whyUs/individual.svg";
import { ReactComponent as Professional } from '../../../../local/svg/whyUs/professinal.svg';
import { ReactComponent as Varriety } from '../../../../local/svg/whyUs/variety.svg';
import { ReactComponent as Technology } from "../../../../local/svg/whyUs/technology.svg";
import { isMobile } from "react-device-detect";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import './WhyUs.scss';

export const WhyUs: FC = () => {
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
      };
    return(
        <section className={`why-us why-us__is-mobile-${isMobile}`}>
            <h2 className="why-us__heading">
                <div className="why-us__heading-border"> </div>
                Почему именно мы?
            </h2>
            <div className="why-us__line-container">
                <div className="why-us__svg-line-container">
                    <div className="why-us__svg-container">
                        <Varriety className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Широкий ассортимент <br/>продукции
                        </p>
                    </div>
                    <div className="why-us__svg-container">
                        <Varanity className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Гарантия качества
                        </p>
                    </div>
                    <div className="why-us__svg-container">
                        <Individual className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Индивидуальный <br/>подход к каждому <br/>клиенту
                        </p>
                    </div>
                </div>
                <div className="why-us__svg-line-container">
                    <div className="why-us__svg-container">
                        <Professional className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Профессионализм <br/>и опыт
                        </p>    
                    </div>
                    <div className="why-us__svg-container">
                        <Technology className="why-us__svg-element"  />
                        <p className="why-us__svg-title">
                            Инновационные <br/>технологии
                        </p>
                    </div>
                    <div className="why-us__svg-container">
                        <Deadlines className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Соблюдение сроков
                        </p>
                    </div>
                </div>
            </div>
            <Carousel className="why-us__slider-container">
                    <div className="why-us__svg-container">
                        <Varriety className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Широкий ассортимент <br/>продукции
                        </p>
                    </div>
                    <div className="why-us__svg-container">
                        <Varanity className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Гарантия качества
                        </p>
                    </div>
                    <div className="why-us__svg-container">
                        <Individual className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Индивидуальный <br/>подход к каждому <br/>клиенту
                        </p>
                    </div>
                    <div className="why-us__svg-container">
                        <Professional className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Профессионализм <br/>и опыт
                        </p>    
                    </div>
                    <div className="why-us__svg-container">
                        <Technology className="why-us__svg-element"  />
                        <p className="why-us__svg-title">
                            Инновационные <br/>технологии
                        </p>
                    </div>
                    <div className="why-us__svg-container">
                        <Deadlines className="why-us__svg-element" />
                        <p className="why-us__svg-title">
                            Соблюдение сроков
                        </p>
                    </div>
            </Carousel>
        </section>
    );
}