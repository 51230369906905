import { FC, useEffect, useRef } from "react";
import aero from '../../../local/png/products/aero.webp';
import superAero from '../../../local/png/products/super-aero.webp';
import rehau from '../../../local/png/products/Rehau.webp';
import kale from '../../../local/png/products/kale.webp';
import { isMobile } from "react-device-detect";
import './ProductsDetails.scss';
import { bl } from "../../../BL/BL";
import { useSelector } from "react-redux";
import { pathSelectors } from "../../../store/path/path.selectors";
import { Link } from "react-router-dom";
import { Credits } from "../MainPage/Credits/Credits";
import { ReactComponent as Arrow } from '../../../local/svg/details/details.svg';

export const ProductsDetails: FC = () => {
    const currentAnkor = useSelector(pathSelectors.getCurrentProductAnkor);

    useEffect(() => {
        bl.scrollToSection(currentAnkor);
    }, [currentAnkor]);

    const elementRef = useRef<HTMLDivElement>(null);

    const onWheelEvent = (event: React.WheelEvent<HTMLDivElement>) => {
        event.preventDefault();
        const targetElement = elementRef.current;
        targetElement?.scrollTo({
            left: targetElement.scrollLeft + event.deltaY,
            top: targetElement.scrollTop + 0,
            //behavior: "smooth"
        });
    }

    return(
        <div ref={elementRef} className={`details details__is-mobile-${isMobile}`} onWheel={event => isMobile ? null :  onWheelEvent(event)}>
            {/*<div className="details__nav">
                <Link className="details__nav-link" to='/'>Главная</Link><p className="details__nav-title"><pre/> — Подробнее</p>
    </div>*/}
            <div className="details__nav">
                <Link className="details__nav-link" to='/'><Arrow className="details__nav-link-svg" /></Link>
            </div>
            <section className="details__product-container" id="aero">
                <img className="details__product-img aero" src={aero} />
                <div className="details__product-info-container">
                    <h3 className="details__product-info-heading">
                        ПРОФИЛЬ BRUSBOX AERO (60-3)
                    </h3>
                    <p className="details__product-info-title">
                        Технические характеристики:<br />
                        - конструктивная ширина — 60 мм.<br />
                        - количество камер — 3 шт.<br />
                        - толщина лицевых стенок — 2.5 мм.<br />
                        - максимальная толщина заполнения — 32 мм.<br />
                        - сопротивление теплопередаче — 0,82 м2С/Вт (без армирования.)<br />
                    </p>
                    <p className="details__product-info-title">
                        Данный представитель профильных систем Brusbox является лидером продаж : <br className="details__product-info-br" />
                        экономия бюджета, не опасаясь за качество <br className="details__product-info-br" />
                        и дизайн готового изделия.<br />
                    </p>
                </div>
            </section>
            <section className="details__product-container" id="super-aero">
                <img className="details__product-img super-aero" src={superAero} />
                <div className="details__product-info-container">
                    <h3 className="details__product-info-heading">
                        BRUSBOX Профиль X SUPER AERO (70-5)
                    </h3>
                    <p className="details__product-info-title">
                        Технические характеристики:<br />
                        - конструктивная ширина — 70 мм.<br />
                        - количество камер — 5 шт.<br />
                        - толщина лицевых стенок — 2.5 мм.<br />
                        - максимальная толщины заполнения — 40 мм.<br />
                        - сопротивление теплопередаче — 0.97 м2С/Вт (без армирования).<br />
                    </p>
                    <p className="details__product-info-title">
                        Данная система - яркий пример гармоничного сочетания толщины профиля <br className="details__product-info-br" />
                        и стеклопакетов, что значительно повышает тепло и звукозащитные свойства изделия.<br />
                    </p>
                </div>
            </section>
            <section className="details__product-container" id="rehau">
                <img className="details__product-img rehau" src={rehau} />
                <div className="details__product-info-container">
                    <h3 className="details__product-info-heading">
                        Профиль Rehau Grazio (70-5) 
                    </h3>
                    <p className="details__product-info-title details__product-info-title-rehau">
                        Технические характеристики:<br />
                        - конструктивная ширина  70 мм.<br />
                        - количество камер  5 шт.<br />
                        - толщина стеклопакета max до  40 мм.<br />
                    </p>
                    <p className="details__product-info-title details__product-info-title-rehau">
                        Оконный профиль Rehau Grazio рекомендован для изготовления оконных систем при <br className="details__product-info-br" />
                        загородном домостроении, возведении таунхаусов, реконструкции жилых объектов <br className="details__product-info-br" />
                        и коммерческого фонда. Конструкция идеальна для обеспечения панорамного вида <br className="details__product-info-br" />
                        за счёт минимальной высоты коробки и створок. 
                    </p>
                    <p className="details__product-info-title details__product-info-title-rehau">
                        Превосходные теплосберегающие свойства профиля позволяют снизить <br className="details__product-info-br" />
                        финансовые расходы на отопление в холодный период. В зависимости от пожеланий <br className="details__product-info-br" />
                        заказчиков, немецкая новинка Grazio представляется в различных цветовых решениях, <br className="details__product-info-br" />
                        что позволяет воплотить в жизнь индивидуальные дизайнерские проекты.
                    </p>
                </div>
            </section>
            <section className="details__product-container" id="kale">
                <img className="details__product-img kale" src={kale} />
                <div className="details__product-info-container">
                    <h3 className="details__product-info-heading">
                        Фурнитура KALE
                    </h3>
                    <p className="details__product-info-title">
                        Производитель: Турция<br />
                    </p>
                    <p className="details__product-info-title">
                        Компания Kale является крупнейшим европейским производителем оконной <br className="details__product-info-br" />
                        и дверной фурнитуры. Kale привлекают высокой надежностью и невысокой <br className="details__product-info-br" />
                        стоимостью. <br className="details__product-info-br" />
                    </p>
                    <p className="details__product-info-title">
                        В процессе производства контроль качества осуществляется каждые 15 <br className="details__product-info-br" />
                        минут. Продукция компании имеет немецкий, турецкий, голландский и <br className="details__product-info-br" />
                        русский сертификаты качества.<br className="details__product-info-br" />
                    </p>
                    <p className="details__product-info-title">
                        Важные составляющие черты фурнитуры Kale: <br />
                        - высокая устойчивость к коррозии.<br />
                        - простота регулировки.<br />
                        - безопастность для детей (ручка с замком).<br />
                    </p>
                </div>
            </section>
            { isMobile ? <Credits /> : null}
        </div>
    );
}