import { FC, Suspense, useEffect, useRef } from "react";
import { Banner } from "./Banner/Banner";
import { Products } from "./Products/Products";
import { WhyUs } from "./WhyUs/WhyUs";
import { Partners } from "./Partners/Partners";
import { Contact } from "./Contact/Contact";
import { About } from "./About/About";
import { Contacts } from "./Contacts/Contacts";
import { Credits } from "./Credits/Credits";
import { isMobile } from "react-device-detect";
import './MainPage.scss';
import { pathSelectors } from "../../../store/path/path.selectors";
import { useSelector } from "react-redux";
import { bl } from "../../../BL/BL";
import React from "react";
/*
const Banner = React.lazy(() => import("./Banner/Banner").then(({ Banner }) => ({ default: Banner })))
const Products = React.lazy(() => import("./Products/Products").then(({ Products }) => ({ default: Products })))
const WhyUs = React.lazy(() => import("./WhyUs/WhyUs").then(({ WhyUs }) => ({ default: WhyUs })));
const Partners = React.lazy(() => import("./Partners/Partners").then(({ Partners }) => ({ default: Partners })));
const Contact = React.lazy(() => import("./Contact/Contact").then(({ Contact }) => ({ default: Contact })));
const About = React.lazy(() => import("./About/About").then(({ About }) => ({ default: About })));
const Contacts = React.lazy(() => import("./Contacts/Contacts").then(({ Contacts }) => ({ default: Contacts })));
const Credits = React.lazy(() => import("./Credits/Credits").then(({ Credits }) => ({ default: Credits })));
*/
export const MainPage: FC = () => {

    const currentAnkor = useSelector(pathSelectors.getCurrentProductAnkor);

    useEffect(() => {
        bl.scrollToSection(currentAnkor);
    }, [currentAnkor]);
    
    const elementRef = useRef<HTMLDivElement>(null);

    const onWheelEvent = (event: React.WheelEvent<HTMLDivElement>) => {
        event.preventDefault();
        const targetElement = elementRef.current;
        targetElement?.scrollTo({
            left: targetElement.scrollLeft + event.deltaY,
            top: targetElement.scrollTop + 0,
            //behavior: "smooth"
        });
    }

    return(
        <div ref={elementRef} className={`main-page main-page__is-mobile-${isMobile}`} onWheel={event => isMobile ? null : onWheelEvent(event)}>
            <Banner />
            <Products />
            <WhyUs />
            <Partners />
            <Contact />
            <About />
            <Contacts />
            <Credits />

            {/*<Suspense fallback={<div>Loading...</div>}>
                <WhyUs />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <Partners />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <Contact />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <About />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <Contacts />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <Credits />
    </Suspense>*/}
        </div>
    );
}