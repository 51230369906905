import { FC } from "react";
import { ReactComponent as Arrow } from '../../../../local/svg/about/arrow.svg';
import { AnimatedSection } from "./AnimatedSection/AnimatedSection";
import { isMobile } from "react-device-detect";
import './Partners.scss';

export const Partners: FC = () => {
    return(
        <section className={`partners partners__is-mobile-${isMobile}`}>
            <div className="partners__heading-container">
                <div className="partners__heading-border"> </div>
                <h2 className="partners__heading-title">
                    С кем мы <br className="partners__heading-br"/>сотрудничаем
                </h2>
                <Arrow className="partners__heading-svg" />
            </div>
            <AnimatedSection />
        </section>
    );
}