import { dal } from "../DAL/dal";

const scrollToSection = (section:string) => {
    const element = document.getElementById(section);
    if(element)  element.scrollIntoView({ behavior: 'smooth' });
};

const buildTelegrammMessage = (name: string, phone: string, commentary: string) => {
    return `Клиент ${name} хочет связаться с вами по телефону ${phone}. Комментарий к письму: ${commentary}`;
}

const sendContactForm = (contactForm: string): void => {
    dal.sendMailServiceForm(contactForm);
}

const sendTelegrammMessage = (message: string) => {
    dal.sendTelegramBotMessage(message);
}

export const bl = {
    sendTelegrammMessage,
    buildTelegrammMessage,
    scrollToSection,
    sendContactForm
}