import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const pathSlice = createSlice({
    name: 'path',
    initialState: {
      ankor: 'aero',
      //msgSendStatus: false,
    },
    reducers: {
        setAnkor(state, action: PayloadAction<string>) {
            state.ankor = action.payload;
        },
        /*setMessageSendedStatus(state, action: PayloadAction<boolean>) {
            state.msgSendStatus = action.payload;
        },*/
    },
});

  
export const pathActions = pathSlice.actions;
export default pathSlice.reducer;