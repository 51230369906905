import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as MenuBtn } from '../../local/svg/header/open-menu.svg';
import { ReactComponent as CloseMenuBtn } from '../../local/svg/header/close-menu.svg';
import { ReactComponent as Logo } from '../../local/svg/header/logo.svg';
import { isMobile } from "react-device-detect";
import './Header.scss';
import { bl } from "../../BL/BL";
import { useAppDispatch } from "../../store/store.hooks";
import { pathActions } from "../../store/path/path.slice";

export const Header: FC = () => {
    const dispatch = useAppDispatch();

    const [isMenuOpenStatus, setIsMenuOpenStatus] = useState<Boolean>(false);

    const handleClickOnLink = (sectionId: string) => {
        dispatch(pathActions.setAnkor(sectionId));
        bl.scrollToSection(sectionId);
        setIsMenuOpenStatus(false);
    }

    return(
        <header className={`header header__is-mobile-${isMobile} header__is-mobile-${isMobile}__is-menu-opened-${isMenuOpenStatus}`}>
            <div className={`header__small-menu header__small-menu__is-menu-opened-${isMenuOpenStatus}`}>
                <div className="header-menu__svg">
                    <MenuBtn  className="header-menu__svg-open" onClick={() => setIsMenuOpenStatus(true)}/>
                    <CloseMenuBtn  className="header-menu__svg-close" onClick={() => setIsMenuOpenStatus(false)}/>
                </div>
                <div className="header-logo__container">
                    <NavLink to='/#main-page' onClick={() => handleClickOnLink('main-page')}>
                        <Logo className="header-logo__svg" />

                    </NavLink>
                    <h1 className="header-logo__label">
                        ЛидерПласт
                    </h1>
                </div>
            </div>
            <div className={`header__large-menu header__large-menu__is-menu-opened-${isMenuOpenStatus}`}>
                <nav className="header-nav__container">
                    <NavLink to='/#main-page' className="header-nav__link" onClick={() => handleClickOnLink('main-page')}>
                        Главная
                    </NavLink>
                    <NavLink to='/#about' className="header-nav__link" onClick={() => handleClickOnLink('about')}>
                        О нас
                    </NavLink>
                    <NavLink to='/#contacts' className="header-nav__link" onClick={() => handleClickOnLink('contacts')}>
                        Контакты
                    </NavLink>
                </nav>
                <div className="header-number__container">
                    <a href="tel:+375336294249" className="header-number__element">
                        МТС +375 (33) 629 42 49
                    </a>
                    <a href="tel:+375445122575" className="header-number__element">
                        А1 +375 (44) 512 25 75
                    </a>
                </div>
            </div>

        </header>
    );
}