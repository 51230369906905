import { FC, useEffect, useState } from "react";
import bkg from '../../../../local/png/contactUs/contactUs.webp';
import { isMobile } from "react-device-detect";
import './Contact.scss';
import { bl } from "../../../../BL/BL";
import { useSelector } from "react-redux";
import { pathActions } from "../../../../store/path/path.slice";
import { pathSelectors } from "../../../../store/path/path.selectors";
import { useAppDispatch } from "../../../../store/store.hooks";

//Contact form and credits
export const Contact: FC = () => {
    //const dispatch = useAppDispatch();
    const screenInitialWidth = window.innerWidth;
    const screenInitialHeight = window.innerHeight;
    const [nameInputValue, setNameInputValue ] = useState<string>('');
    const [phoneNumberValue, setPhoneNumberValue ] = useState<string>('');
    const [phoneNumberMessage, setPhoneNumberMessage ] = useState<string>('');

    const [commsValue, setCommsValue ] = useState<string>('');

    //const messageSended = useSelector(pathSelectors.getMessageSendedStatus);

    const [messageSended, setMessageSendedStatus] = useState<boolean>(false);

    useEffect(() => {
       if(messageSended) setTimeout(() => /*pathActions.*/setMessageSendedStatus(false), 7000);
    }, [messageSended]);

    const clearForm = () => {
        setNameInputValue('');
        setPhoneNumberValue('');
        setCommsValue('');
    }

    const sendContactMail = (event:any ) => {
        event.preventDefault();
        window.resizeTo(screenInitialWidth, screenInitialHeight);
        setPhoneNumberMessage(phoneNumberValue);
        //bl.sendContactForm(event.target);
        bl.sendTelegrammMessage(bl.buildTelegrammMessage(nameInputValue,phoneNumberValue,commsValue));
        setMessageSendedStatus(true);
        clearForm();
        
    }

    return(
        <section className={`contact contact__is-mobile-${isMobile}`} id="contact-us">
            <div className={`contact__message-sended contact__message-sended-${messageSended}`}>Сообщение отправлено - скоро с вами свяжутся по вашему номеру {phoneNumberMessage}</div>
            <div className="contact__ui-container">
                <div className="contact__header-container">
                    <h2 className="contact__header-title">
                        Поможем решить ваш <br className="contact__header-br"/>вопрос
                    </h2>
                    <p className="contact__header-description">
                        Оставьте заявку и мы свяжемся с Вами <br/>
                        для обсуждения всех деталей.
                    </p>
                </div>
                <form className="contact__form-container" onSubmit={event => sendContactMail(event)}>
                    <input value={nameInputValue} onChange={event => setNameInputValue(event.target.value)} required minLength={1} className="contact__form-element name_input" name="name_input" placeholder="Имя" type="text"/>
                    <input value={phoneNumberValue} onChange={event => setPhoneNumberValue(event.target.value)} required className="contact__form-element phone_number" name="phone_number" placeholder="Телефон" type="tel"/>
                    <textarea value={commsValue} onChange={event => setCommsValue(event.target.value)} className="contact__form-element comment_input" name="comment_input" placeholder="Комментарий"/>
                    <button className="contact__form-element submit" type="submit" >Оставить заявку</button>
                </form>
            </div>
            <img className="contact__bkg" src={bkg} />
        </section>
    );
}