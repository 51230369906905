import { FC } from "react";
import bkg from '../../../../local/png/contacts/contacts.webp';
import { isMobile } from "react-device-detect";
import './Contacts.scss';

export const Contacts: FC = () => {
    return(
        <section className={`contacts contacts__is-mobile-${isMobile}`} id="contacts">
            <h2 className="contacts__header">
                <div className="contacts__header-border"></div>
                Контакты
            </h2>
            <div className="contacts__adress-container">
                <p className="contacts__adress-location">
                    г. Могилев,пр-т Шмидта,70а
                </p>            
                <a href="mailto:liderplastgroup@mail.ru" className="contacts__adress-mail">
                    liderplastgroup@mail.ru
                </a>
            </div>
            <div className="contacts__phone-container">
                <a href="tel:+375336294249" className="contacts__phone-element">
                    МТС +375 (33) 629 42 49
                </a>            
                <a href="tel:+375445122575" className="contacts__phone-element">
                    А1 +375 (44) 512 25 75
                </a>
            </div>
            <img className="contacts__bkg" src={bkg} />
        </section>
    );
}